<template>
  <v-container>
    <v-dialog
        v-model="dialog"
        persistent
        :max-width="900"
        :retain-focus="false"
    >
      <v-card>
        <v-card-title class="text-h5" id="title">
           <span v-if="text">
            {{ text }}
          </span>
          <span v-else-if="count>1">
            Bist du sicher, dass du <b>{{count}}</b> Einträge unwiderruflich löschen möchtest?
          </span>
          <span v-else>
            Bist du sicher, dass du<b> einen</b> Eintrag unwiderruflich löschen möchtest?
          </span>
        </v-card-title>
        <v-card-text>
          <v-form ref="formular">
            <v-container>

            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              :color="buttonColor"
              @click="sure()"
          >
            {{ buttonText }}
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="cancel()"
          >
            Abbrechen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
/**
 * Snackbar to show user successful and not successful tries to edit, create or delete a value
 * @displayName Alert
 */
export default {
  name: "SureToDelete",
  props: {
    dialog: Boolean,
    count: {
      type: Number,
      default(){
        return 0
      }
    },
    buttonText: {
      type: String,
      default(){
        return 'Löschen'
      }
    },
    text:String,
    buttonColor: {
      type: String,
      default(){
        return 'error'
      }
    },
  },
  methods:{
    sure(){
      this.$emit('sure')
    },
    cancel(){
      this.$emit('cancel')
    }
  }
}
</script>
